import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { Chart, PieController, ArcElement, Tooltip, Legend } from 'chart.js'

Chart.register(PieController, ArcElement, Tooltip, Legend);
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, DrawSVGPlugin);

document.addEventListener("DOMContentLoaded", function (event) {

  // Scroll handlers
  const getStartedButton = document.getElementById("get-started-button");
  const moreButton = document.getElementById("more-button");
  const statsButton = document.getElementById("stats-button");
  const sectionIntro = document.getElementById("section-intro");
  const sectionWhat = document.getElementById("section-what");
  const sectionStats = document.getElementById("section-stats");

  const scrollToSection = (button, section) => {
    const scrollBehavior = {
      behavior: "smooth"
    };

    if (!button || !section) return;

    button.addEventListener("click", (event) => {
      event.preventDefault();
      section.scrollIntoView(scrollBehavior);
    });
  };

  scrollToSection(getStartedButton, sectionIntro);
  scrollToSection(moreButton, sectionWhat);
  scrollToSection(statsButton, sectionStats);

  // GSAP Animations

  // Turn on if you want to see the scroll triggers start/end position
  const showMarkers = false;
  const windowHeight = window.innerHeight;

  const rivers = document.querySelectorAll(".river-animation");
  if (rivers.length) {
    rivers.forEach((river) => {
      gsap.timeline({
        defaults: {
          duration: 1,
          ease: 'none'
        },
        scrollTrigger: {
          trigger: river,
          scrub: true,
          start: () => `-=${windowHeight/2}`,
          end: () => `+=${windowHeight/2}`,
          markers: showMarkers
        }
      }).fromTo(river.querySelectorAll('path'), {
        drawSVG: "0% 0%"
      }, {
        drawSVG: "0% 100%"
      }, {
        stagger: {
          amount: 15,
          each: 1
        }
      });
    })
  }

  const stats = document.querySelectorAll(".stat-animation");
  if (stats) {
    stats.forEach((stat) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: stat,
          scrub: false,
          start: "top bottom",
          once: true,
          markers: showMarkers
        }
      }).from(stat, {
        textContent: 0,
        duration: 1,
        ease: "power1.in",
        snap: { textContent: 1 },
        modifiers: {
          textContent: function (textContent) {
            return gsap.utils
              .snap(1, textContent)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        }
      });
    })
  }

  // Charts
  const renderChart = () => {
    let chart;
    const chartCtx = document.getElementById('chart');

    if (!chartCtx) return;

    const chartData = {
      labels: JSON.parse(chartCtx.dataset.chartLabels),
      datasets: [{
        label: '',
        data: JSON.parse(chartCtx.dataset.chartData),
        backgroundColor: JSON.parse(chartCtx.dataset.chartColors),
        hoverOffset: 4,
        borderWidth: 0,
        outerGlowColor: '#00FFFF',
        outerGlowWidth: 10,
        legend: {
          display: false
        }
      }]
    };

    const glowPlugin = {
      beforeDraw: (chart, args, options) => {
        const { ctx } = chart;
        ctx.save();
        ctx.shadowColor = "rgba(0,255,255, 0.4)";
        ctx.shadowBlur = 20;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.restore();
      }
    };

    const getOrCreateLegendList = (chart, id) => {
      const legendContainer = document.getElementById(id);
      let listContainer = legendContainer.querySelector('ul');

      if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.margin = 0;
        listContainer.style.padding = 0;

        legendContainer.appendChild(listContainer);
      }

      return listContainer;
    };

    const htmlLegendPlugin = {
      id: 'htmlLegend',
      afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        // Remove old legend items
        while (ul.firstChild) {
          ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach(item => {
          const li = document.createElement('li');
          li.style.alignItems = 'center';
          li.style.cursor = 'pointer';
          li.style.display = 'flex';
          li.style.flexDirection = 'row';
          li.style.marginLeft = '0';
          li.style.marginBottom = '8px';

          li.onclick = () => {
            chart.toggleDataVisibility(item.index);
            chart.update();
          };

          // Color box
          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + 'px';
          boxSpan.style.display = 'inline-block';
          boxSpan.style.flexShrink = 0;
          boxSpan.style.height = '20px';
          boxSpan.style.marginRight = '10px';
          boxSpan.style.width = '20px';
          boxSpan.style.borderRadius = '100%';
          boxSpan.style.border = '1px solid #fff';

          // Text
          const textContainer = document.createElement('p');
          textContainer.style.color = '#ffffff';
          textContainer.style.fontSize = 16;
          textContainer.style.margin = 0;
          textContainer.style.padding = 0;
          textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);

          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        });
      }
    };

    if (chart) return;

    chart = new Chart(chartCtx, {
      type: 'pie',
      data: chartData,
      plugins: [glowPlugin, htmlLegendPlugin],
      options: {
        plugins: {
          htmlLegend: {
            containerID: 'legend-container',
          },
          legend: {
            display: false,
          }
        }
      }
    });
  };

  ScrollTrigger.create({
    trigger: '#section-expect',
    scrub: false,
    start: "top bottom",
    once: true,
    markers: showMarkers,
    onToggle: (self) => {
      if (self.isActive) {
        renderChart();
      }
    }
  });
});